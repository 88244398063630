html,
body {
	height: 100%;
	background-color: #f3eadd;

	margin: 0;
	border: 0;

	text-align: center;
	font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
	color: #4e4b46;
}

/* Portraits */

.portrait {
	height: 150px;
	width: 150px;
	z-index: 2;
}

.portrait_name {
	margin: 0;
	background: white;
	border: #4e4b46 2px;
	z-index: 20;
}

/* Child block */

.child-block {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	column-gap: 50px;
	margin-top: 50px;
}

.child-block-enter {
	opacity: 0;
	transform: scale(0.9);
}

.child-block-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 500ms, transform 800ms;
}

.child-block-exit {
	opacity: 1;
}

.child-block-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 500ms, transform 800ms;
}

/* Fallback */

.fallback {
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
}


.fallback>span {
	margin: calc(50vh - 50px);
}